export const HOME_LINK = `${process.env.WWW_URL}/`;
export const REGISTER_LINK = '/create-account';
export const WHAT_WE_TREAT_LINK = `${process.env.WWW_URL}/what-we-treat`;
export const URGENT_CARE_LINK = `${process.env.WWW_URL}/urgent-care`;
export const PRIMARY_CARE_LINK = `${process.env.WWW_URL}/primary-care`;
export const MENTAL_HEALTH_LINK = `${process.env.WWW_URL}/mental-health/`;
export const OUR_PROVIDERS_LINK = `${process.env.WWW_URL}/become-a-provider/our-providers/`;
export const PROVIDER_RECRUITMENT_LINK = `${process.env.WWW_URL}/provider-recruitment`;
export const EXECUTIVE_TEAM_LINK = `${process.env.WWW_URL}/company/executive-team/`;

export const BRANDING_DATA = {
  mdlive: {
    displayName: 'MDLIVE',
    logo: '/static/svg/mdlive_logo_2024.svg',
    alt: 'MDLIVE Logo',
    favicon: '/static/favicon.ico',
  },
  evernorth: {
    displayName: 'Evernorth Virtual Care',
    logo: '/static/evernorth_logo.png',
    alt: 'Evernorth Virtual Care Logo',
    favicon: '/static/favicon_evernorth.svg',
  },
};
export interface BrandingData {
  displayName: string;
  logo: string;
  alt: string;
  favicon: string;
}

export interface InternalBrandingLogo {
  logo: string;
  alt: string;
}

export const DEFAULT_LOGO = {
  src: '/static/svg/mdlive_logo_2024.svg',
  alt: 'MDLIVE Logo',
};

export interface TopLevelMenuInterface {
  label: string;
  kind: 'menu';
  content: SubMenuInterface[];
}

export interface SubMenuInterface {
  label: string;
  kind: 'link' | 'nextlink';
  href: string;
}

export type MenuInterface = (TopLevelMenuInterface | SubMenuInterface)[];

export const HEADER_LINKS: MenuInterface = [
  {
    label: 'Patients',
    kind: 'menu',
    content: [
      {
        label: 'What We Treat',
        href: WHAT_WE_TREAT_LINK,
        kind: 'link',
      },
      {
        label: 'Urgent Care',
        href: URGENT_CARE_LINK,
        kind: 'nextlink',
      },
      {
        label: 'Mental Health',
        href: MENTAL_HEALTH_LINK,
        kind: 'nextlink',
      },
      {
        label: 'Primary Care',
        href: PRIMARY_CARE_LINK,
        kind: 'nextlink',
      },
      {
        label: 'Dermatology',
        href: `${process.env.WWW_URL}/dermatology`,
        kind: 'link',
      },
      {
        label: 'Blog',
        href: `${process.env.WWW_URL}/blog/`,
        kind: 'link',
      },
    ],
  },
  {
    label: 'Businesses',
    kind: 'menu',
    content: [
      {
        label: 'Health Plans',
        href: `${process.env.WWW_URL}/health-plans/`,
        kind: 'link',
      },
      {
        label: 'Employers',
        href: `${process.env.WWW_URL}/employers/`,
        kind: 'link',
      },
      {
        label: 'Health Systems',
        href: `${process.env.WWW_URL}/health-systems/`,
        kind: 'link',
      },
      {
        label: 'Virtual Care Resources',
        href: `${process.env.WWW_URL}/company/resources/`,
        kind: 'link',
      },
    ],
  },
  {
    label: 'Providers',
    kind: 'menu',
    content: [
      {
        label: 'Our Providers',
        href: OUR_PROVIDERS_LINK,
        kind: 'link',
      },
      {
        label: 'Become a Provider',
        href: PROVIDER_RECRUITMENT_LINK,
        kind: 'nextlink',
      },
    ],
  },
  {
    label: 'Contact Us',
    kind: 'link',
    href: `${process.env.WWW_URL}/contact-us`,
  },
];

export const FOOTER_LINKS = [
  {
    label: 'Patients',
    links: [
      {
        label: 'How it works',
        href: 'https://www.mdlive.com/how-telehealth-works/',
      },
      { label: 'What we treat', href: WHAT_WE_TREAT_LINK },
      { label: 'What it costs', href: '#what-we-treat' },
      { label: 'Testimonials', href: 'https://www.mdlive.com/testimonials/' },
      {
        label: 'Our providers',
        href: OUR_PROVIDERS_LINK,
      },
      {
        label: 'Frequently asked questions',
        href: 'https://www.mdlive.com/patients/faqs/',
      },
    ],
  },
  {
    label: 'Businesses',
    links: [
      {
        label: 'Health Systems',
        href: 'https://www.mdlive.com/health-systems/',
      },
      { label: 'Health Plans', href: 'https://www.mdlive.com/health-plans/' },
      { label: 'Employers', href: 'https://www.mdlive.com/employers/' },
      {
        label: 'Resellers and TPAs',
        href: 'https://www.mdlive.com/resellers-and-tpas/',
      },
      { label: 'Brokers', href: 'https://www.mdlive.com/brokers/' },
      { label: 'Government', href: 'https://www.mdlive.com/government/' },
      { label: 'Become a Provider', href: 'https://www.mdlive.com/provider' },
    ],
  },
  {
    label: 'Company',
    links: [
      { label: 'Contact Us', href: 'https://www.mdlive.com/contact-us/' },
      {
        label: 'Executive Team',
        href: EXECUTIVE_TEAM_LINK,
      },
      { label: 'Blog', href: 'https://www.mdlive.com/blog/' },
      { label: 'Resources', href: 'https://www.mdlive.com/company/resources/' },
      {
        label: 'Upcoming Events',
        href: 'https://www.mdlive.com/company/upcoming-events/',
      },
      { label: 'Careers', href: '/careers' },
      { label: 'Employee Login', href: 'https://ew41.ultipro.com/' },
      {
        label: 'News & Information',
        href: 'https://www.mdlive.com/company/press-room/',
      },
      { label: 'Evernorth', href: 'https://www.evernorth.com/' },
    ],
  },
  {
    label: 'Legal',
    links: [
      { label: 'Terms of Use', href: 'https://www.mdlive.com/terms-of-use/' },
      {
        label: 'Privacy Policy',
        href: 'https://www.mdlive.com/privacy-policy/',
      },
      {
        label: 'MDLIVE Medical Group Informed Consent',
        href: 'https://www.mdlive.com/mdlive-medical-group-informed-consent/',
      },
      { label: 'Disclaimer', href: 'https://www.mdlive.com/disclaimer/' },
      { label: 'ADA Policy', href: 'https://www.mdlive.com/ada-policy/' },
      {
        label: 'Notice of Non-Discrimination',
        href: 'https://www.mdlive.com/notice-of-non-discrimination/',
      },
      {
        label: 'Compliance Hotline',
        href:
          'https://secure.ethicspoint.com/domain/media/en/gui/55030/index.html',
      },
      {
        label: 'Behavioral Health Treatment Agreement',
        href: 'https://www.mdlive.com/behavioral-health-treatment-agreement/',
      },
    ],
  },
];

export const FOOTER_MISC_LINKS = {
  smallPrintProvidersLink: OUR_PROVIDERS_LINK,
};

export const SOCIAL_LINKS = [
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/MDLIVETelehealth',
    imageSrc: '/static/social-icon-fb.png',
  },
  {
    label: 'Twitter',
    href: 'https://twitter.com/MDLIVEInc',
    imageSrc: '/static/social-icon-twitter.png',
  },
  {
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/company/mdlive-inc-',
    imageSrc: '/static/social-icon-linkedin.png',
  },
  {
    label: 'Youtube',
    href: 'https://www.youtube.com/user/MDLiveCare',
    imageSrc: '/static/social-icon-yt.png',
  },
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/mdlive_inc/',
    imageSrc: '/static/social-icon-instagram.png',
  },
];

export const HERO_LINKS = {
  downloadOurApp: {
    label: 'Download our app',
    href: null,
  },
  whatWeTreat: {
    label: 'What we treat',
    href: '#what-we-treat',
  },
  howMuchDoesItCost: {
    label: 'How much does it cost?',
    href: '#what-we-treat',
  },
  howMDLIVEWorks: {
    label: 'How MDLIVE works',
    href: 'https://www.mdlive.com/how-telehealth-works/',
  },
  faq: {
    label: 'Frequently asked questions',
    href: 'https://www.mdlive.com/patients/faqs/',
  },
};
